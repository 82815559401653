import DEFAULT_TOKEN_LIST from '../config/constants/tokenLists/pancake-default.tokenlist.json'

const getTokenLogoURL = (address: string) => {
  const foundToken = DEFAULT_TOKEN_LIST.tokens.filter((token) => {
    return token.address.toLowerCase() === address.toLowerCase()
  })

  return foundToken.length > 0 ? foundToken[0].logoURI : `https://tokens.9mm.pro/pulse/${address}.png`
}

export default getTokenLogoURL
